@import "~bootstrap/dist/css/bootstrap.css";
@import 'bootstrap/scss/bootstrap';

$fa-font-path: "~font-awesome/fonts";

@import 'font-awesome/scss/font-awesome.scss';
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

svg { position:fixed; top:0; left:0; height:100%; width:100% }

.header-link {
  color: gainsboro;
  font-family: 'Verdana','Courier','Monaco';
  font-size: smaller;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
}

#content-desktop {
  display: block;
}

#content-device {
  display: none;
}

@media screen and (max-width: 950px) {
  #content-desktop {
    display: none;
  }
  #content-device {
    display: block;
  }
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1.1rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.2rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1.3rem;
  }
}
